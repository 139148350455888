import './App.css';
import numeral from 'numeral';

import { useState, useEffect, useMemo } from 'react';
// import rustmagic.png , rustmagic-coin.svg ,  rustbounty.svg
import Rustmagic from './rain.png'
import rmagicCoin from './rain-coin.svg'
import bounty from './rustbounty.svg'

function App() {
  const [LBData, setLBData] = useState([]);
  const [LBData2, setLBData2] = useState([]);

  const [LBTab, setLBTab] = useState('Rustmagic');

    // useEffect(() => {
    //   console.log('1')
    //   fetch("http://127.0.0.1:3001/api/rustmagic", {

    //   })
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log(data)
    //       data.users = data.users.slice(0, 10);
    //       setLBData(data.users);
    //     })
    //     .catch((error) => console.log(error));
    // }, []);
    const [expiryTime2, setExpiryTime2] = useState("7 sep 2024 00:00:00");

    const [countdownTime2, setCountdownTime2] = useState({
      countdownDays: "",
      countdownHours: "",
      countdownMinutes: "",
      countdownSeconds: "",
    });
    
    const countdownTimer2 = () => {
      const timeInterval = setInterval(() => {
        const countdownDateTime = new Date(expiryTime2).getTime();
        const currentTime = new Date().getTime();
        const remainingDayTime = countdownDateTime - currentTime;
        const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
        const totalHours = Math.floor(
          (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const totalMinutes = Math.floor(
          (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const totalSeconds = Math.floor(
          (remainingDayTime % (1000 * 60)) / 1000
        );
    
        const runningCountdownTime = {
          countdownDays: totalDays,
          countdownHours: totalHours,
          countdownMinutes: totalMinutes,
          countdownSeconds: totalSeconds,
        };
    
        setCountdownTime2(runningCountdownTime);
    
        if (remainingDayTime < 0) {
          clearInterval(timeInterval);
          setExpiryTime2(false);
        }
      }, 1000);
    };

    const [expiryTime, setExpiryTime] = useState("7 sep 2024 00:00:00");
const [countdownTime, setCountdownTime] = useState({
  countdownDays: "",
  countdownHours: "",
  countdownMinutes: "",
  countdownSeconds: "",
});


const countdownTimer = () => {
  const timeInterval = setInterval(() => {
    const countdownDateTime = new Date(expiryTime).getTime();
    const currentTime = new Date().getTime();
    const remainingDayTime = countdownDateTime - currentTime;
    const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
    const totalHours = Math.floor(
      (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const totalMinutes = Math.floor(
      (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
    );
    const totalSeconds = Math.floor(
      (remainingDayTime % (1000 * 60)) / 1000
    );

    const runningCountdownTime = {
      countdownDays: totalDays,
      countdownHours: totalHours,
      countdownMinutes: totalMinutes,
      countdownSeconds: totalSeconds,
    };

    setCountdownTime(runningCountdownTime);

    if (remainingDayTime < 0) {
      clearInterval(timeInterval);
      setExpiryTime(false);
    }
  }, 1000);
};


useEffect(() => {
  countdownTimer();
  countdownTimer2();
});

    const prices = [100,50,25,15,10,0,0,0,0,0];
    const prices2 = [50,25,10,5,3,2,2,1,1,1];
    useMemo(() => {
      fetch("https://api.sametrewards.com:3021/api/raingg", {

      })
        .then((response) => response.json())
        .then((data) => {

          data.results.sort((a, b) => parseFloat(b.wagered) - parseFloat(a.wagered));
          data.results = data.results.slice(0, 10);
          setLBData(data.results);
        })
        .catch((error) => console.log(error));
    }, []);
   
  return (
    <>
    <header>
  <nav>
    <div className="logo-title" >
      <img src="https://files.kick.com/images/user/26623401/profile_image/conversion/48dfd100-cc94-471b-9825-80fa324a7dc3-fullsize.webp" className="logo" />
      <h1 className="title">DEJENKO</h1>
    </div>
    <ul className="nav-links"></ul>
  </nav>
</header>

<div className="toggle-images" style={{ marginTop: 50 }} bis_skin_checked={1}>
  <img
    className="game-image clash-image"
    id="clashImage"
    src={Rustmagic}
    style={{ cursor: "pointer", opacity: 1 }}
    onClick={()=>{ setLBTab('Rustmagic')}}
  />

</div>


<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>

      {/* <div className="body" />

      <div className='siteData'>
      <span>
            <span className="glow">$250</span> CODE <span className="glow">DEJENKO</span> Leaderboard!
      </span>
      <div className='leaderboard'>
      <div className="slide-container">
  
  
      {/* {LBData[0] && (<div className="wrapper glow2">
    <div className="clash-card barbarian">
      <div className="clash-card__image clash-card__image--barbarian">
        <img src={LBData[0].avatar}  />
      </div>
      <div className="clash-card__unit-description">
      {LBData[0].username}
      </div>

      <div className="clash-card__unit-stats clash-card__unit-stats--barbarian clearfix">
        <div className="one-third">
          <div className="stat">1</div>
          <div className="stat-value">Place</div>
        </div>

        <div className="one-third">
          <div className="stat">{LBData[0].wagered}</div>
          <div className="stat-value">Wagered</div>
        </div>

        <div className="one-third no-border">
          <div className="stat">{prices[0]}</div>
          <div className="stat-value">Prize</div>
        </div>

      </div>

    </div>
  </div>)}
  {LBData[1] && (<div className="wrapper2 glow2">
    <div className="clash-card barbarian">
      <div className="clash-card__image clash-card__image--barbarian">
        <img src={LBData[1].avatar}  />
      </div>
      <div className="clash-card__unit-description">
      {LBData[1].username}
      </div>

      <div className="clash-card__unit-stats clash-card__unit-stats--barbarian clearfix">
        <div className="one-third">
          <div className="stat">2</div>
          <div className="stat-value">Place</div>
        </div>

        <div className="one-third">
          <div className="stat">{LBData[1].wagered}</div>
          <div className="stat-value">Wagered</div>
        </div>

        <div className="one-third no-border">
          <div className="stat">{prices[1]}</div>
          <div className="stat-value">Prize</div>
        </div>

      </div>

    </div>
    {LBData[2] && (<div className="wrapper3 glow2">
    <div className="clash-card barbarian">
      <div className="clash-card__image clash-card__image--barbarian">
        <img src={LBData[2].avatar}  />
      </div>
      <div className="clash-card__unit-description">
      {LBData[2].username}
      </div>

      <div className="clash-card__unit-stats clash-card__unit-stats--barbarian clearfix">
        <div className="one-third">
          <div className="stat">3</div>
          <div className="stat-value">Place</div>
        </div>

        <div className="one-third">
          <div className="stat">{LBData[2].wagered}</div>
          <div className="stat-value">Wagered</div>
        </div>

        <div className="one-third no-border">
          <div className="stat">{prices[2]}</div>
          <div className="stat-value">Prize</div>
        </div>

      </div>

    </div>
    </div>)}
    
  </div>)} */}
  {/* <div class="css-esk2ah" bis_skin_checked="1">
                <div class="css-2w2ovy" bis_skin_checked="1"></div>
  <div class="css-gqrafh" bis_skin_checked="1">
                    <div class="css-jehefp" bis_skin_checked="1"><img alt="***********'s avatar" srcset="https://avatars.steamstatic.com/b590f279a47d76655ac8dc822b269a556aa95948_medium.jpg" src="/_next/image?url=https%3A%2F%2Favatars.akamai.steamstatic.com%2F8d2a2e1eeafaee2dbee30c1a19db9cb8bfebe753_full.jpg&amp;w=256&amp;q=75" width="96" height="96" decoding="async" data-nimg="1" class="css-1wgwpc8" loading="lazy" style={{color: 'transparent'}} />
                    <div class="css-hca0vm" bis_skin_checked="1"><span class="css-15a1lq3">***********</span></div>
                    <div class="css-7ahevu ejrykqo0" bis_skin_checked="1"><span class="css-1vqddgv">Wagered </span><span class="css-18icuxn">
                            <div class="css-1y0ox2o" bis_skin_checked="1">
                                <span class="css-114dvlx">1,343.62</span>
                            </div>
                        </span></div><span class="css-v4675v">
                        <div class="css-1y0ox2o" bis_skin_checked="1">
                            <div data-v-1d580398="" class="price-wrapper" style={{color: '#FFF'}} bis_skin_checked="1">
                                <div class="price-image-wrapper" bis_skin_checked="1">
                                    <img src={rmagicCoin} class="gem"/>
                                </div>
                                100
                            </div>
                        </div>
                    </span>
                </div>
<div class="css-oijls1" bis_skin_checked="1"><img alt="***************'s avatar" srcset="https://avatars.steamstatic.com/3ac4c6e07a8a5392d22cdccb87df70b92a82cf9f_medium.jpg" width="96" height="96" decoding="async" data-nimg="1" class="css-1wgwpc8" loading="lazy" style={{color: 'transparent'}}/ >
                    <div class="css-hca0vm" bis_skin_checked="1"><span class="css-15a1lq3">***************</span></div>
                    <div class="css-7ahevu ejrykqo0" bis_skin_checked="1"><span class="css-1vqddgv">Wagered </span><span class="css-18icuxn">
                            <div class="css-1y0ox2o" bis_skin_checked="1">
                                <span class="css-114dvlx">11,569.27</span>
                            </div>
                        </span></div><span class="css-v4675v">
                        <div class="css-1y0ox2o" bis_skin_checked="1">
                            <div data-v-1d580398="" class="price-wrapper" style={{color: '#FFF'}} bis_skin_checked="1">
                                <div class="price-image-wrapper" bis_skin_checked="1">
                                    <img src={rmagicCoin} class="gem"/>
                                </div>
                                200
                            </div>
                        </div>
                    </span>
                </div>
<div class="css-jehefp" bis_skin_checked="1"><img alt="********'s avatar" srcset="https://avatars.steamstatic.com/943538131dfc8d694718af3cc3e2c938b2f46ff9_medium.jpg" width="96" height="96" decoding="async" data-nimg="1" class="css-1wgwpc8" loading="lazy" style={{color: 'transparent'}}/>
                    <div class="css-hca0vm" bis_skin_checked="1"><span class="css-15a1lq3">********</span></div>
                    <div class="css-7ahevu ejrykqo0" bis_skin_checked="1"><span class="css-1vqddgv">Wagered </span><span class="css-18icuxn">
                            <div class="css-1y0ox2o" bis_skin_checked="1">
                                <span class="css-114dvlx">916.22</span>
                            </div>
                        </span></div><span class="css-v4675v">
                        <div class="css-1y0ox2o" bis_skin_checked="1">
                            <div data-v-1d580398="" class="price-wrapper" style={{color: '#FFF'}} bis_skin_checked="1">
                                <div class="price-image-wrapper" bis_skin_checked="1">
                                    <img src={rmagicCoin} class="gem"/>
                                </div>
                                50
                            </div>
                        </div>
                    </span>
                </div>
                </div>
                </div>
                </div>

        <div className="relative font-inter antialiased">

<main className="relative  flex flex-col justify-center overflow-hidden">
    <div className="w-full max-w-6xl mx-auto px-4 md:px-5 py-24">
        <div className="flex justify-center">

            <div className="w-full shadow-xl rounded-2xl">
                
                <div className="p-3">

                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="text-[13px] text-slate-500/70 ">
                                <tr className='background'>
                                    <th className="px-5 py-2 first:pl-3 last:pr-3  first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0">
                                        <div className="black-color text-left">Place</div>
                                    </th>                                        
                                    <th className="px-5 py-2 first:pl-3 last:pr-3  first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0">
                                        <div className="black-color text-left">Name</div>
                                    </th>
                                    <th className="px-5 py-2 first:pl-3 last:pr-3  first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0">
                                        <div className="black-color  text-left">Wagered</div>
                                    </th>
                                    <th className="px-5 py-2 first:pl-3 last:pr-3  first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0">
                                        <div className="black-color  text-left">Prize</div>
                                    </th>                                      
                                </tr>
                            </thead>
                            <tbody className="text-sm font-medium">
                            
                                {LBData.map((user, index) => {

                                    {if (index < 3) 
                                      return (
                                        <></>
                                      );
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className="px-5 py-5 border-b border-slate-100 size-bigger">{index + 1}</td>
                                            <td className="px-5 py-5 border-b border-slate-100 size-bigger">{user.username}</td>
                                            <td className="px-5 py-5 border-b border-slate-100 size-bigger">{user.wagered}</td>
                                            <td className="gemLabel border-b border-slate-100 size-bigger"><img src="https://ytr.gghttps://ytr.gg/assets/rustmagic-coin.svg" className="gem" />{prices[index]}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </div>
    </div>
</main>


</div>
      </div>
      
      </div> */} 
{LBTab == 'Rustmagic' && (
<div data-v-1d580398="" data-v-e4097664="" id="appContent">
    <div data-v-1d580398="">
      <div data-v-1d580398="" className="row text">
        <span data-v-1d580398="" className="title">
          <div data-v-1d580398="" className="price-wrapper glow">
            <div className="price-image-wrapper">
              <img
                src={rmagicCoin}
                className="gem"
                style={{ top: 5, height: 40, width: 40, left: 0 }}
              />
            </div>{" "}
            200
          </div>{" "}
          CODE <span className="glow">DEJENKO</span> RainGG Leaderboard!
        </span>
        <span data-v-1d580398="" className="subtitle" style={{ color: "#FFF" }}>
          <br/>
          <br/>
          <i>The leaderboard updates every 15min.</i>
          <br />
          <br />
          <br />
        </span>
      </div>
      <div className="css-esk2ah">
        <div className="css-2w2ovy">
          <div className="css-gqrafh">
            <div className="css-jehefp">
            {LBData.map((student, index) => ( index == 1 ? (
              <img
                srcSet={student.avatar}
                width={96}
                height={96}
                decoding="async"
                data-nimg={1}
                className="css-1wgwpc8"
                loading="lazy"
                style={{ color: "transparent" }}
              />
              ) : null ))}
              <div className="css-hca0vm">
              {LBData.map((student, index) => ( index == 1 ? (
                   <span className="css-15a1lq3">{student.username}</span>) : null
            ))}
                
              </div>
              <div className="css-7ahevu ejrykqo0">
                <span className="css-1vqddgv">Wagered </span>
                <span className="css-18icuxn">
                  <div className="css-1y0ox2o">
                    {LBData.map((student, index) => ( index == 1 ? (
                   <span className="css-114dvlx">{numeral(student.wagered).format('0,0.00')}</span>) : null
            ))}
                  </div>
                </span>
              </div>
              <span className="css-v4675v">
                <div className="css-1y0ox2o">
                  <div
                    data-v-1d580398=""
                    className="price-wrapper"
                    style={{ color: "#FFF" }}
                  >
                    <div className="price-image-wrapper">
                      <img src={rmagicCoin} className="gem" />
                    </div>
                    {prices[1]}
                  </div>
                </div>
              </span>
            </div>
            <div className="css-oijls1">
              {LBData.map((student, index) => ( index == 0 ? (
              <img
                srcSet={student.avatar}
                width={96}
                height={96}
                decoding="async"
                data-nimg={1}
                className="css-1wgwpc8"
                loading="lazy"
                style={{ color: "transparent" }}
              />
              ) : null ))}
              <div className="css-hca0vm">
                
                {LBData.map((student, index) => ( index == 0 ? (
                   <span className="css-15a1lq3">{student.username}</span>) : null
            ))}
              </div>
              <div className="css-7ahevu ejrykqo0">
                <span className="css-1vqddgv">Wagered </span>
                <span className="css-18icuxn">
                  <div className="css-1y0ox2o">
                  {LBData.map((student, index) => ( index == 0 ? (
                   <span className="css-114dvlx">{numeral(student.wagered).format('0,0.00')}</span>) : null
                ))}
                  </div>
                </span>
              </div>
              <span className="css-v4675v">
                <div className="css-1y0ox2o">
                  <div
                    data-v-1d580398=""
                    className="price-wrapper"
                    style={{ color: "#FFF" }}
                  >
                    <div className="price-image-wrapper">
                      <img src={rmagicCoin} className="gem" />
                    </div>
                    {prices[0]}
                  </div>
                </div>
              </span>
            </div>
            <div className="css-jehefp">
            {LBData.map((student, index) => ( index == 2 ? (
              <img
                srcSet={student.avatar}
                width={96}
                height={96}
                decoding="async"
                data-nimg={1}
                className="css-1wgwpc8"
                loading="lazy"
                style={{ color: "transparent" }}
              />
              ) : null ))}

              <div className="css-hca0vm">
              {LBData.map((student, index) => ( index == 2 ? (
                   <span className="css-15a1lq3">{student.username}</span>) : null
            ))}
              </div>
              <div className="css-7ahevu ejrykqo0">
                <span className="css-1vqddgv">Wagered </span>
                <span className="css-18icuxn">
                  <div className="css-1y0ox2o">
                  {LBData.map((student, index) => ( index == 2 ? (
                   <span className="css-114dvlx">{numeral(student.wagered).format('0,0.00')}</span>) : null
                ))}
                  </div>
                </span>
              </div>
              <span className="css-v4675v">
                <div className="css-1y0ox2o">
                  <div
                    data-v-1d580398=""
                    className="price-wrapper"
                    style={{ color: "#FFF" }}
                  >
                    <div className="price-image-wrapper">
                      <img src={rmagicCoin} className="gem" />
                    </div>
                    {prices[2]}
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div data-v-1d580398="" className="countdown">
            <div data-v-1d580398="">
              <span data-v-1d580398="" className="title">
                <span style={{ color: "#48ffa4" }} className="glow">
                  NEW
                </span>{" "}
                Leaderboard starts in{" "}
              </span>
              <span data-v-1d580398="" className="subtitle" id="countdown">
                {countdownTime.countdownDays} Days {countdownTime.countdownHours}:{countdownTime.countdownMinutes}:{countdownTime.countdownSeconds}
              </span>
            </div>
          </div>

        </div>
<div data-v-1d580398="" className="row list details" bis_skin_checked={1}>
  <div data-v-1d580398="" className="hide-mobile col-2" bis_skin_checked={1}>
    {" "}
    Place{" "}
  </div>
  <div data-v-1d580398="" className="col-5" bis_skin_checked={1}>
    {" "}
    User{" "}
  </div>
  <div data-v-1d580398="" className="col-2" bis_skin_checked={1}>
    {" "}
    Prize{" "}
  </div>
  <div data-v-1d580398="" className="col-3" bis_skin_checked={1}>
    {" "}
    Wagered{" "}
  </div>
</div>
{LBData.map((user, index) => {

{if (index < 3) 
  return (
    <></>
  );
}

return (
  <div data-v-1d580398="" className="row list row-cols-5" bis_skin_checked={1}>
  <div data-v-1d580398="" className="hide-mobile col-2" bis_skin_checked={1}>
    {/* <b data-v-1d580398="">#</b>{index+1} */}
    <b>{index+1}th</b>
  </div>
  <div data-v-1d580398="" className="col-5" bis_skin_checked={1}>
    
    <span data-v-1d580398="">{user.username}</span>
  </div>
  <div data-v-1d580398="" className="col-2" bis_skin_checked={1}>
    <div data-v-1d580398="" className="price-wrapper prize-label" bis_skin_checked={1}>
      
      <div className="price-image-wrapper2" bis_skin_checked={1}>
        <img src={rmagicCoin} className="gem" />
      </div> {prices[index]}
    </div>
  </div>
  <div data-v-1d580398="" className="col-3 wagerlabel" bis_skin_checked={1}>
    <div
      data-v-1d580398=""
      className="price-wrapper"
      style={{ color: "#eee" }}
      bis_skin_checked={1}
    >
      <div
        className="price-image-wrapper"
        style={{ height: "0rem", width: "0rem", marginRight: 0 }}
        bis_skin_checked={1}
      />{" "}
      <div className="price-image-wrapper3" bis_skin_checked={1}>
        <img src={rmagicCoin} className="gem" />
      </div>{numeral(user.wagered).format('0,0.00')}{" "}
    </div>{" "}
  </div>
</div>
);
})}
        
        

        {/* <main className="relative  flex flex-col justify-center overflow-hidden">
    <div className="w-full max-w-6xl mx-auto px-4 md:px-5 py-24">
        <div className="flex justify-center">

            <div className="w-full shadow-xl rounded-2xl">
                
                <div className="p-3">

                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="text-[13px] text-slate-500/70 ">
                                <tr className='background'>
                                    <th className="px-5 py-2 first:pl-3 last:pr-3  first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0">
                                        <div className="black-color text-left">Place</div>
                                    </th>                                        
                                    <th className="px-5 py-2 first:pl-3 last:pr-3  first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0">
                                        <div className="black-color text-left">Name</div>
                                    </th>
                                    <th className="px-5 py-2 first:pl-3 last:pr-3  first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0">
                                        <div className="black-color  text-left">Wagered</div>
                                    </th>
                                    <th className="px-5 py-2 first:pl-3 last:pr-3  first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0">
                                        <div className="black-color  text-left">Prize</div>
                                    </th>                                      
                                </tr>
                            </thead>
                            <tbody className="text-sm font-medium">
                            
                                {LBData.map((user, index) => {

                                    {if (index < 3) 
                                      return (
                                        <></>
                                      );
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className="px-5 py-5 border-b border-slate-100 size-bigger">{index + 1}</td>
                                            <td className="px-5 py-5 border-b border-slate-100 size-bigger">{user.username}</td>
                                            <td className="px-5 py-5 border-b border-slate-100 size-bigger">{user.wagered}</td>
                                            <td className="gemLabel border-b border-slate-100 size-bigger"><img src={rmagicCoin} className="gem2" />{prices[index]}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </div>
    </div>
</main> */}
      </div>
    </div>
  </div>)}
  
    </>

  );
}

export default App;
